import React, { Component } from 'react';
import { Col, Container, Row, Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { config } from './config'
import { storeCredentials } from './utils'
import { Alert } from 'react-bootstrap';

interface SigninProps {
  navigate: any
  location: any
}

type SigninStates = {
  logError: string
  logErrors: any
  logEmail: string
  logPassword: string
  socError: string
}

class Signin extends Component <SigninProps, SigninStates> {
  constructor (props: SigninProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logEmail: '',
      logPassword: '',
      socError: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as SigninStates)
  }

  componentDidMount(){
    const queryParams = new URLSearchParams(this.props.location.search)
    const token = queryParams.get('token')
    if (token) {
      storeCredentials(token)
      setTimeout(()=>window.location.href = "/", 200)
    }
    const error = queryParams.get('error')
    if (error) {
      this.setState({ socError: error })
    }
  }
  
  login = (e:any) => {
    e.preventDefault()
    fetch(
      config.app.apiUri + '/api/v1/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail,
          password: this.state.logPassword
        })
      })
      .then((response) => {
        if (response.status !== 200) {return {status: 'error', message: response.statusText}}
        return response.json()
      })
      .then((json) => {
        if (json.status === 'success') {
          storeCredentials(json.token)
          this.props.navigate('/')
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={4} className="my-5">
              <>
                <h1 className="display-4 text-center mb-3">Sign in</h1>
                <p className="text-muted text-center mb-5">Sign in to access our dashboard.</p>
                <form onSubmit={(e)=>this.login(e)}>
                  <div className="form-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" value={this.state.logEmail} name="logEmail" onChange={this.inputChange} placeholder="name@address.com" />
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col>
                        <Form.Label>Password</Form.Label>
                      </Col>
                      <Col xs="auto">
                        <Form.Text as={Link} className="small text-muted" to="/forgot">
                          Forgot password?
                        </Form.Text>
                      </Col>
                    </Row>
                    <Form.Control type="password" value={this.state.logPassword} name="logPassword" onChange={this.inputChange} placeholder="Enter your password" />
                  </div>
                  <Button size="lg" className="w-100 mb-3" type="submit">
                    Sign in
                  </Button>
                  { this.state.logError !== '' ?
                    <Alert variant="danger" className='text-center' onClose={() => this.setState({logError: ''})} dismissible>
                      {this.state.logError}
                    </Alert>
                  :null}
                  <p className="text-center">
                    <small className="text-muted text-center">
                      Don't have an account yet? <Link to="/sign-up">Sign up</Link>.
                    </small>
                  </p>
                </form>
                
                <div className="d-flex w-100 pt-3 gap-2 pb-3">
                  <a
                    href="https://backend.chatady.com/api/v1/auth/google"
                    className="btn btn-outline-secondary d-flex w-100 justify-content-center align-items-center rounded"
                  >
                    <svg className="me-2" fill="currentColor" height={18} width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                      <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                    </svg>
                    Google
                  </a>

                  <a
                    href="https://backend.chatady.com/api/v1/auth/github"
                    className="btn btn-outline-secondary d-flex w-100 justify-content-center align-items-center rounded"
                  >
                    <svg className="me-2" fill="currentColor" height={18} width={18} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                      <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                    </svg>
                    Github
                  </a>
                </div>
                
                { this.state.socError !== '' ?
                  <Alert variant="danger" className='text-center' onClose={() => this.setState({socError: ''})} dismissible>
                    {this.state.socError}
                  </Alert>
                :null}                
              </>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default function SigninWithNavigation() {
  const navigate = useNavigate()
  const location = useLocation()
  return <Signin navigate={navigate} location={location}/>
}