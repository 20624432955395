import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Form, InputGroup, Button } from 'react-bootstrap'
import { config } from './config'
import { Alert } from 'react-bootstrap';

interface ForgotProps {
  navigate: any
}

type ForgotStates = {
  logError: string
  logErrors: any
  logMessage: string
  logEmail: string
}

class Forgot extends Component <ForgotProps, ForgotStates> {
  constructor (props: ForgotProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logMessage: '',
      logEmail: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as ForgotStates)
  }

  forgot = (e: any) => {
    e.preventDefault()
    fetch(
      config.app.apiUri + '/api/v1/me/forgot', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail
        })
      })
      .then((response) => {
        if (response.status !== 200) {return {status: 'error', message: response.statusText}}
        return response.json()
      })
      .then((json) => {
        if (json.status === 'success') {
          this.setState({
            logMessage: json.message
          })
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={4} className="my-5">
              <>
                <h1 className="display-4 text-center mb-3">Forgot Password</h1>
                <p className="text-muted text-center mb-5">Request password reset link.</p>
                <form onSubmit={(e)=>this.forgot(e)}>
                  <div className="form-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" value={this.state.logEmail} name="logEmail" onChange={this.inputChange} placeholder="name@address.com" />
                  </div>
                  <Button size="lg" className="w-100 mb-3" type="submit">
                    Send reset link
                  </Button>
                  { this.state.logMessage !== '' ?
                    <Alert variant="success" className='text-center' onClose={() => this.setState({logMessage: ''})} dismissible>
                      {this.state.logMessage}
                    </Alert>
                  :null}
                  { this.state.logError !== '' ?
                    <Alert variant="danger" className='text-center' onClose={() => this.setState({logError: ''})} dismissible>
                      {this.state.logError}
                    </Alert>
                  :null}
                  <p className="text-center">
                    <small className="text-muted text-center">
                      Already have an account? <Link to="/sign-in">Log in</Link>.
                    </small>
                  </p>
                </form>
              </>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default function ForgotWithNavigation() {
  const navigate = useNavigate()
  return <Forgot navigate={navigate}/>
}